import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // DEV: REFACTOR 'toggleable' -> 'element' when fully converted to RMV
  static targets = ["toggleable", "element"];
  static values = { attribute: String };

  /**
   * Toggles the visibility of each target element.
   * Iterates over the list of element targets and sets their hidden property.
   * to the opposite of its current value.
   *
   * @function toggle
   */
  toggle() {
    this.elementTargets.forEach((element) => {
      element.hidden = !element.hidden;
    });
  }

  /**
   * Use the state of a component to toggle the attribute/value of a targeted element.
   * Example: When you click on an accordion the hidden input value inside can be set to true or false based on the open or closed state of the accordion.
   * You can target any element and specify any attribute you want: <rmv-input type="hidden" value="true/false">... or <rmv-checkbox checked="true/false">...
   *
   * @param {Event} e - The event object triggered by the user interaction.
   * @property {string} [attributeValue='value'] - The attribute to be toggled. Defaults to 'value' if not specified.
   * @property {Array} elementTargets - The list of target elements to be toggled.
   */
  toggleValue(e) {
    // On default the attribute to be toggled is 'value', but you can use any attribute you want using data-toggle-attribute-value="..." on the element.
    const attribute = this.attributeValue || "value";
    const component = e.target;
    const componentName = component.nodeName.toLowerCase();

    // Ensure the toggle event is only processed for the top-level component.
    if (component !== e.currentTarget) return;

    // 1. Define the accepted components with their attributes that allow toggling.
    // We do not want a rmv-card or rmv-column to be toggleable.
    // DEV: You can add new components here and their respective attributes to be toggled.
    const componentAttributes = {
      "rmv-accordion": "open",
      "rmv-select": "open",
      "rmv-action-trigger": "open",
      "rmv-datepicker": "open",
      "rmv-modal": "open",
      "rmv-radio": "checked",
      "rmv-checkbox": "checked",
      "rmv-folder": "selected",
    };

    if (componentAttributes.hasOwnProperty(componentName)) {
      // 2. Retrieve the attribute from the current component that is being toggled.
      // Example: 'open' attribute from <rmv-accordion open>...
      const componentAttribute = componentAttributes[componentName];

      // 3. Take the component attribute value and place it on the target element attribute.
      // Example: <rmv-input value="true/false">
      this.elementTargets.forEach((element) => {
        if (
          element[attribute] === undefined ||
          typeof element[attribute] == "boolean"
        ) {
          element.setAttribute(attribute, !component[componentAttribute]);
        } else {
          element[attribute] = !component[componentAttribute];
        }
      });
    }
  }

  show() {
    if (this.hasToggleableTarget) {
      this.toggleableTarget.removeAttribute("hidden");
    }
  }

  hide() {
    if (this.hasToggleableTarget) {
      this.toggleableTarget.setAttribute("hidden", "true");
    }
  }
}
